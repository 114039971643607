import React from 'react';
import Hero from '../Hero';
import Section from '../Section';

export const B4TKContent = ({ hero, blurb }) => {
  return (
    <>
      <Hero images={hero.images} title={hero.text} />
      <Section>
        <p className="text-3xl font-baloo text-black text-left sm:text-justify">
          {blurb}
        </p>
      </Section>
    </>
  );
};

export default B4TKContent;
