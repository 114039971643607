import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import OpenHouseContent from '../components/Events/OpenHouseContent';

export const OpenHousePageTemplate = ({ hero, blurb }) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <OpenHouseContent hero={hero} blurb={blurb} />
    </div>
  );
};

const OpenHousePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <OpenHousePageTemplate
        hero={frontmatter.hero}
        blurb={frontmatter.blurb}
      />
    </Layout>
  );
};

export default OpenHousePage;

export const openHousePageQuery = graphql`
  query OpenHousePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "open-house-page" } }) {
      frontmatter {
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          text
        }
        blurb
      }
    }
  }
`;
